import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
} from "react";

import {
  getActiveAdds,
  getUser,
  postClickAdds,
  startSession,
  TypeAdds,
  updateLoginStreak,
  UserInterface,
} from "../service/users/users";

interface UserContextType {
  user: UserInterface;
  setUser: React.Dispatch<any>;
  handleGetUserByTelegramId: (id: string) => Promise<void>;
  handleStartSession: (stationId: string, telegramId: string) => Promise<void>;
  handleActiveAdds: () => void;
  activeAdds: TypeAdds | null;
  showAd: boolean;
  setShowAd: React.Dispatch<any>;
  handleClickActiveAdds: (addId: number, telegramId: number) => void;
  telegramId: string;
  setTelegramId: React.Dispatch<string>;
  day: number;
  point: number;
}

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<any>(null);
  const [activeAdds, setActiveAdds] = useState<TypeAdds | null>(null);
  const [showAd, setShowAd] = useState(true);
  const [day, setDay] = useState<number>(0);
  const [point, setPoint] = useState<number>(0);

  // const urlParams = new URLSearchParams(window.location.search);

  // const telegramId = urlParams.get("telegram_id") || "458982324";

  // const telegram = window.Telegram.WebApp;

  const [telegramId, setTelegramId] = useState<string>("");

  useEffect(() => {
    const initializeTelegramId = () => {
      // @ts-ignore
      const telegram = window.Telegram.WebApp;
      telegram.ready();

      const urlParams = new URLSearchParams(window.location.search);
      const urlTelegramId = urlParams.get("telegram_id");

      if (telegram.initDataUnsafe?.user?.id) {
        setTelegramId(telegram.initDataUnsafe.user.id.toString());
      } else if (urlTelegramId) {
        setTelegramId(urlTelegramId);
      } else {
        console.warn("Telegram ID not found in initData or URL");
      }
    };

    initializeTelegramId();
  }, []);

  const handleGetUserByTelegramId = async (id: string): Promise<void> => {
    try {
      const user = await getUser(id as string);

      if (user) {
        setUser(user);
      } else {
        console.error("Failed to fetch User");
      }
    } catch (error) {
      console.error("Failed to fetch User", error);
    }
  };

  const handleStartSession = async (telegramId: string) => {
    try {
      await startSession({
        telegram_id: telegramId,
        session_id: "1448",
        device: "iPhone",
      });
    } catch (error) {
      console.error("Failed to update user", error);
    }
  };
  const [isLoading, setIsLoading] = useState(false);

  const fetchStreak = async (telegramId: string) => {
    if (!telegramId || user?.telegram_id === telegramId || isLoading) return;

    setIsLoading(true);
    try {
      if (!user) {
        await handleGetUserByTelegramId(telegramId);
      }
      const streakResponse = await updateLoginStreak(telegramId);
      if (streakResponse) {
        setDay(streakResponse.day);
        setPoint(streakResponse.point);
      }
    } catch (error) {
      console.error("Failed to fetch user", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleActiveAdds = async () => {
    try {
      const adds = await getActiveAdds();
      setActiveAdds(adds);
    } catch (error) {
      console.error("Failed to fetch active adds", error);
      setShowAd(false);
    }
  };

  const handleClickActiveAdds = async (addId: number, telegramId: number) => {
    try {
      await postClickAdds(addId, telegramId);
    } catch (error) {
      console.log("Click add", error);
    }
  };

  useEffect(() => {
    if (telegramId) {
      fetchStreak(telegramId);
    }
    if (user) {
      handleActiveAdds();
    }
  }, [telegramId]);

  // useEffect(() => {
  //   console.log("Ad shown:", showAd, "Active adds:", activeAdds);
  // }, [showAd, activeAdds]);

  return (
    <UserContext.Provider
      value={{
        user,
        setUser,
        handleGetUserByTelegramId,
        handleStartSession,
        handleActiveAdds,
        handleClickActiveAdds,
        activeAdds,
        showAd,
        setShowAd,
        telegramId,
        setTelegramId,
        day,
        point,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const useUser = (): UserContextType => {
  const context = useContext(UserContext);
  if (context === undefined) {
    throw new Error("useUser must be used within an UserProvider");
  }
  return context;
};
