import { useEffect, useState } from "react";

import { Button } from "../Button/Button";
import { LiderItem } from "../liderItem/liderItem";

import { useUser } from "../../context/UserContext";
import { useLeagues } from "../../context/LeaguesContext";

import { ReactComponent as ArrowLeft } from "../../assets/icons/arrow-left-icom.svg";
import { ReactComponent as ArrowRight } from "../../assets/icons/arrow-rigth-icon.svg";

import { ReactComponent as QuestionIcon } from "../../assets/icons/questionIcon.svg";

import style from "./UserStatus.module.css";
import { useTranslate } from "../../i18n";
import UpYourRank from "../HelpPages/UpYourRank/ListenRadio";

export const UserStatus = () => {
  const { user, handleGetUserByTelegramId } = useUser();
  const translate = useTranslate();
  // const { wallet } = useWallet();
  const {
    leaderBoard,
    leagueTopUsers,
    usersLeague,
    userCard,
    totalLeagues,
    selectedLeague,
    handleLeagueSwipe,
    handleLeagueUpgrade,
    passedLeagues,
  } = useLeagues();
  const [open, setOpen] = useState(false);

  // @ts-ignore
  const photoUrl = window?.Telegram?.WebApp?.initDataUnsafe?.user?.photo_url;
  // @ts-ignore
  // const userName = window?.Telegram?.WebApp?.initDataUnsafe?.user?.name;
  // console.log(userName);

  const [openApprove, setOpenApprove] = useState(false);
  const requiredImpulse = selectedLeague?.required_impulses;
  const userImpulse = user?.wallet?.balance || userCard?.wallet;

  const handleOpenApprove = () => {
    setOpenApprove(true);
  };

  const handleCloseApprove = () => {
    setOpenApprove(false);
  };

  const handlePreviousClick = async () => {
    const currentLeagueIndex = totalLeagues
      .map((item) => item.id)
      .indexOf(selectedLeague?.id);
    const nextLeague = totalLeagues[currentLeagueIndex - 1];
    await handleLeagueSwipe(nextLeague.id);
  };

  const handleNextClick = async () => {
    const currentLeagueIndex = totalLeagues
      .map((item) => item.id)
      .indexOf(selectedLeague?.id);
    const nextLeague = totalLeagues[currentLeagueIndex + 1];
    await handleLeagueSwipe(nextLeague.id);
  };

  const oneLeagueUpgrade = async () => {
    await handleLeagueUpgrade(user?.telegram_id as number, selectedLeague?.id);
    // await handleGetUserByTelegramId(user?.telegram_id.toString());
    handleCloseApprove();
  };

  const userLeagueIndex = totalLeagues?.findIndex(
    (league) => league?.id === usersLeague?.id
  );
  const selectedLeagueIndex = totalLeagues?.findIndex(
    (league) => league?.id === selectedLeague?.id
  );
  console.log(usersLeague?.id);

  useEffect(() => {
    if (user?.wallet?.balance) {
      console.log(user.wallet.balance);
    }
  }, [user?.wallet?.balance]);
  return (
    <>
      <div className={style.wrapper}>
        <div className={style.onboardingWrapper}></div>
        {!open ? (
          <>
            <div className={style.head}>
              {!!totalLeagues
                .map((item) => item.id)
                .indexOf(selectedLeague?.id) && (
                <ArrowLeft
                  onClick={handlePreviousClick}
                  className={style.arrowLeft}
                />
              )}
              <div className={style.mainImgWrapper}>
                {!usersLeague?.id ? (
                  <img
                    src={`${selectedLeague?.icon}`}
                    alt="Newbie"
                    className={style.mainImg}
                  />
                ) : (
                  <img
                    src={`https://slushairu.ru${selectedLeague?.icon}`}
                    alt="Newbie"
                    className={style.mainImg}
                  />
                )}
              </div>
              {selectedLeague?.id !== totalLeagues.at(-1)?.id && (
                <ArrowRight
                  onClick={handleNextClick}
                  className={style.arrowRight}
                />
              )}

              <h1 className={style.title}>{selectedLeague?.name}</h1>
            </div>

            {openApprove ? (
              <div className={style.approveWindow}>
                <p className={style.approveTitle}>
                  <p>{translate.transition_1}</p>
                  <p>{translate.transition_2}</p>
                  <p>{selectedLeague?.transition_cost} IMPULSE.</p>
                </p>
                <p className={style.approveText}>{translate.transition_3}</p>
                <div className={style.buttons}>
                  <div
                    onClick={oneLeagueUpgrade}
                    className={style.yesButton}
                  >
                    {translate.transition_yes}
                  </div>
                  <div
                    onClick={handleCloseApprove}
                    className={style.noButton}
                  >
                    {translate.transition_no}
                  </div>
                </div>
              </div>
            ) : (
              <div className={style.wrapperUser}>
                <p className={style.info}>
                  {translate.leaderboard_collect}{" "}
                  {selectedLeague?.required_impulses} Impulse
                  <br />
                  {translate.leaderboard_text2}
                  <br />
                  {translate.leaderboard_text3}{" "}
                  {selectedLeague?.transition_cost} IMPULSE.
                </p>
                <div
                  className={style.questionIcon}
                  onClick={() => setOpen(true)}
                >
                  <QuestionIcon />
                </div>
                {selectedLeague?.id !== undefined && selectedLeague?.id > 4 ? (
                  <p className={style.bonus}>
                    {translate.bonus} x-
                    {Number(selectedLeague.boost_multiplier) % 1 === 0
                      ? Number(selectedLeague.boost_multiplier).toFixed(0)
                      : Number(selectedLeague.boost_multiplier)}
                  </p>
                ) : null}
                <div className={style.aboutUser}>
                  <div className={style.datailInfoUser}>
                    <div className={style.imageWrapper}>
                      {!usersLeague?.id ? (
                        <img
                          className={style.profileImage}
                          src={
                            photoUrl ||
                            `https://slushairu.ru/media/league_icons/Newbie.jpeg`
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className={style.profileImage}
                          src={
                            photoUrl ||
                            `https://slushairu.ru${usersLeague?.icon}`
                          }
                          alt=""
                        />
                      )}
                    </div>
                    <div>
                      <h2 className={style.profileName}>
                        {!!user?.first_name?.trim()
                          ? user.first_name
                          : user?.username}
                      </h2>

                      <p className={style.balance}>
                        {/* {wallet ? wallet.balance : 4588} Impulse */}
                        {userImpulse
                          ? Math.floor(userImpulse as number)
                          : null}{" "}
                        Impulse
                      </p>
                    </div>
                  </div>

                  <p className={style.rating}>#{userCard?.rank}</p>
                </div>
                <>
                  {(passedLeagues.find(
                    (item) => item.id === selectedLeague?.id
                  ) ||
                    selectedLeague?.id === usersLeague?.id) && (
                    <p className={style.done}>DONE</p>
                  )}

                  {usersLeague?.id !== selectedLeague?.id && (
                    <>
                      {selectedLeagueIndex === userLeagueIndex + 1 ? (
                        requiredImpulse &&
                        userImpulse &&
                        requiredImpulse <= userImpulse ? (
                          <Button
                            onClick={handleOpenApprove}
                            access={true}
                            text={"Move"}
                          />
                        ) : (
                          <Button
                            access={false}
                            text={"Not enough Impulses"}
                          />
                        )
                      ) : null}
                    </>
                  )}
                </>
              </div>
            )}

            <div className={style.leaderList}>
              {leaderBoard?.map((item, key) => (
                <LiderItem
                  key={key}
                  userPhoto={
                    !usersLeague?.id
                      ? selectedLeague?.icon
                      : `https://slushairu.ru${selectedLeague?.icon}`
                  }
                  userName={item.first_name}
                  balance={item?.wallet}
                  rank={item.rank}
                />
              ))}
            </div>
          </>
        ) : (
          <div className={style.up_your_rank}>
            <UpYourRank
              onClose={() => setOpen(false)}
              username={user.first_name}
            />
          </div>
        )}
      </div>
    </>
  );
};
