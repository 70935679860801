import React, { FC, useEffect, useState } from "react";
import "./userScore.css";
import { useTranslate } from "../../i18n";
import { useLeagues } from "../../context/LeaguesContext";
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";
import Gif from "../../assets/icons/player/gif.gif";
import VolumeControlMusicians from "../VolumeControlMusicians/VolumeControlMusicians";
import { StarRating } from "../StarRating/StarRating";
import { useUser } from "../../context/UserContext";
import ActiveAdds from "../ActiveAdds/ActiveAdds";

const UserScore: FC<{
  score: string;
  lang: string;
  isPlaying: boolean;
  onTabChange: (value: number) => void;
  music: "song" | "station";
  volume?: number;
  onVolumeChange?: (volume: number) => void;
}> = ({
  score = "0.00",
  lang,
  isPlaying = false,
  onTabChange,
  music,
  volume = 50,
  onVolumeChange,
}) => {
  const translate = useTranslate();
  const { usersLeague } = useLeagues();
  const [isOpenVolume, setIsOpenVolume] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [fadeInContent, setFadeInContent] = useState(false);
  const { showAd } = useUser();

  const handleToggleOpenVolume = () => {
    setIsOpenVolume(!isOpenVolume);
  };
  useEffect(() => {
    if (isPlaying) {
      setShowGif(true);
      setFadeInContent(false);
      const timer = setTimeout(() => {
        setShowGif(false);
        setFadeInContent(true);
      }, 3000);
      return () => clearTimeout(timer);
    } else {
      setShowGif(false);
      setFadeInContent(true);
    }
  }, [isPlaying]);

  useEffect(() => {
    console.log(showAd);
  }, [showAd]);

  return (
    <div className={"bWrapper"}>
      {showGif ? (
        <div className={"balanceWrapper"}>
          <div className={`gifWrapper ${showGif ? "fadeIn" : "fadeOut"}`}>
            <img
              src={Gif}
              alt=""
              className={"imgGif"}
            />
          </div>
        </div>
      ) :
        showAd ? (
        <div className={"balanceWrapper"}>
          <ActiveAdds />
        </div>
        ) :
          (
        <div className={"balanceWrapper"}>
          {music === "station" ? (
            <p
              onClick={() => onTabChange(7)}
              className={"leagueName"}
            >
              {usersLeague?.name}
            </p>
          ) : (
            <div className="volumeWrapper">
              <VolumeIcon onClick={handleToggleOpenVolume} />
              {isOpenVolume && (
                <div className="volumeChange">
                  <VolumeControlMusicians
                    onClose={handleToggleOpenVolume}
                    volume={volume ?? 50}
                    onVolumeChange={onVolumeChange ?? (() => {})}
                  />
                </div>
              )}
            </div>
          )}

          <p className={"balanceTitle"}>{translate.balance_title}</p>
          <h2 className={"balanceScore"}>{score}</h2>
          <h3 className={"balanceSubtitle"}>{translate.balance_subtitle}</h3>
          {music === "song" && <StarRating />}
        </div>
      )}
    </div>

    // <div className={"bWrapper"}>
    //   {showGif ? (
    //     <div className={"balanceWrapper"}>
    //       <div className={`gifWrapper ${showGif ? "fadeIn" : "fadeOut"}`}>
    //         <img
    //           src={Gif}
    //           alt=""
    //           className={"imgGif"}
    //         />
    //       </div>
    //     </div>
    //   ) : (
    //     showAd ? (
    //     <div className={"balanceWrapper"}>
    //       <ActiveAdds />
    //     </div>
    //     ) :
    //     <div className={"balanceWrapper"}>
    //       {music === "station" ? (
    //         <p
    //           onClick={() => onTabChange(7)}
    //           className={"leagueName"}
    //         >
    //           {usersLeague ? usersLeague.name : "Leaderboard"}
    //         </p>
    //       ) : (
    //         <div className="volumeWrapper">
    //           <VolumeIcon onClick={handleToggleOpenVolume} />
    //           {isOpenVolume && (
    //             <div className="volumeChange">
    //               <VolumeControlMusicians
    //                 onClose={handleToggleOpenVolume}
    //                 volume={volume ?? 50}
    //                 onVolumeChange={onVolumeChange ?? (() => {})}
    //               />
    //             </div>
    //           )}
    //         </div>
    //       )}

    //       <p className={"balanceTitle"}>{translate.balance_title}</p>
    //       <h2 className={"balanceScore"}>{score}</h2>
    //       <h3 className={"balanceSubtitle"}>{translate.balance_subtitle}</h3>
    //       {music === "song" && <StarRating />}
    //     </div>
    //   )}
    // </div>
  );
};

export default UserScore;
