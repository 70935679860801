import React, { FC, useEffect, useState } from "react";
import ReferralLink from "../../components/ReferralLink/ReferralLink";
import styles from "./refLink.module.css";
import { translate, useTranslate } from "../../i18n";
import { useUser } from "../../context/UserContext";
import { ReactComponent as TonIcon } from "../../assets/icons/tonIcon.svg";
import { axiosInstance } from "../../service/axiosConfig/axiosConfig";
import { TonConnectButton } from "@tonconnect/ui-react";

const Wallet: FC<{ lang: "en" | "ru" }> = ({ lang }) => {
  const { user, handleGetUserByTelegramId } = useUser();
  const translate = useTranslate();
  const [isOpenSoonPage, setIsOpenSoonPage] = useState(false);

  const toggleSoonPage = () => {
    setIsOpenSoonPage(true);
    setTimeout(() => {
      setIsOpenSoonPage(false);
    }, 3000);
  };

  const [tasks, setTasks] = useState<any[]>([]);
  const [completed, setCompleted] = useState<any[]>([]);

  const fetchTasks = async (id: number) => {
    const data = await axiosInstance
      .get(`api/active-tasks/?telegram_id=${id}`)
      .then((res) => res.data);

    if (data) {
      console.log(data);
      // @ts-ignore
      setTasks([...data.active_subscribe_tasks, ...data.active_social_tasks]);
      // @ts-ignore
      setCompleted([
        ...data.completed_subscribe_tasks,
        ...data.completed_social_tasks,
      ]);
    }
  };

  useEffect(() => {
    if (user?.telegram_id) {
      fetchTasks(user?.telegram_id);
    }
  }, [user]);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const telegramId = urlParams.get("telegram_id");
    if (telegramId) {
      handleGetUserByTelegramId(telegramId as string);
    }
  }, []);

  return (
    <div className={styles.pageWrapper}>
      <h1 className={styles.pageTitle}>{translate.wallet}</h1>
      <p className={styles.pageSubtitle}>{translate.wallet_text_1}</p>
      <p className={styles.pageSubtitle}>{translate.wallet_text_2}</p>
      {/* <button
        className={styles.connectButton}
        onClick={() => toggleSoonPage()}
      >
        <p>Connect wallet</p>
        <TonIcon />
      </button> */}
      <TonConnectButton className={styles.connectButton} />
      {isOpenSoonPage && (
        <p
          className={`${styles.comingSoon} ${
            isOpenSoonPage ? styles.comingSoonVisible : ""
          }`}
        >
          Coming Soon
        </p>
      )}
    </div>
  );
};

export default Wallet;
