import React, { useCallback, useEffect, useRef, useState } from "react";
import TabNavigation from "./components/TabNavigation/TabNavigation";
import Menu from "./components/Menu/Menu";
import Player from "./pages/player/Player";
import Stations from "./pages/stations/Stations";
import Copyright from "./pages/copyright/Copyright";
import RefLink from "./pages/refLink/RefLink";
import { BASE_ORIGIN } from "./service/axiosConfig/axiosConfig";
import { I18nProvider, translate } from "./i18n";
import { UserStatus } from "./components/UserStatus/UserStatus";
import Onboarding from "./components/Onboarding/Onboarding";
import Header from "./components/Header/Header";
import Tasks from "./pages/tasks/Tasks";
import Wallet from "./pages/wallet/wallet";
import Help from "./pages/help/help";
import { useUser } from "./context/UserContext";
import UserAgreement from "./pages/userAgreement/userAgreement";
import { CatalogMusicians } from "./components/CatalogMusicians/CatalogMusicians";
import { AlbumSongs } from "./components/AlbumSongs/AlbumSongs";
import { MusicianAlbums } from "./components/MusicianAlbums/MusicianAlbums";
import { Musicians } from "./pages/musicians/Musicians";
import PlayerControlsMusicians from "./components/PlayerControlsMusicians/PlayerControlsMusicians";
import { FavoritesMusic } from "./components/FavoritesMusic/FavoritesMusic";
import { useMusic } from "./context/MusicContext";
import { GamesPage } from "./pages/games/Games";
import ActiveAdds from "./components/ActiveAdds/ActiveAdds";
import { LanguageSwitcher } from "./components/LanguageSwitcher/LanguageSwitcher ";
import { useLanguage } from "./context/LanguageContext";
import Snowfall from "react-snowfall";
import snow from "../src/assets/icons/snow.png";

// @ts-ignore
export const tg = window.Telegram.WebApp;
interface WebSocketState {
  isConnected: boolean;
  error: string | null;
  reconnectAttempts: number;
  isPlaying: boolean;
}

const App: React.FC = () => {
  React.useEffect(() => {
    tg.ready();
    tg.expand();
    tg.SettingsButton.show();
    tg.SettingsButton.onClick();
    tg.disableVerticalSwipes();
  }, []);

  const { user, handleGetUserByTelegramId } = useUser();
  const { lang, setLang } = useLanguage();
  const urlParams = new URLSearchParams(window.location.search);
  const telegramId =
    tg.initDataUnsafe?.user?.id || urlParams.get("telegram_id");
  // const telegramId = user?.telegram_id.toString()
  const { song } = useMusic();
  const { setIsLanguageSelected } = useLanguage();
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(0);
  const [onArtistOpen, setOnArtistOpen] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [ws, setWs] = useState<WebSocket | null>(null);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const lastPlayingStateRef = useRef<boolean>(false);

  const [state, setState] = useState<WebSocketState>({
    isConnected: false,
    error: null,
    reconnectAttempts: 0,
    isPlaying: false,
  });

  const reconnectTimeoutRef = useRef<NodeJS.Timeout>();
  const MAX_RECONNECT_ATTEMPTS = 5;
  const RECONNECT_DELAY = 4000;

  const [isTelegramReady, setIsTelegramReady] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const updateState = (newState: Partial<WebSocketState>) => {
    setState((prev) => ({ ...prev, ...newState }));
  };

  const checkNetworkStatus = useCallback(() => {
    if (!navigator.onLine) {
      updateState({
        error: "No internet connection",
        isConnected: false,
      });
    } else {
      updateState({ error: null });
      connectWebSocket();
    }
  }, []);

  const handlePlayPause = useCallback(() => {
    if (!state.isConnected) {
      lastPlayingStateRef.current = !state.isPlaying;
      connectWebSocket();
      return;
    }

    if (audioRef.current) {
      if (state.isPlaying) {
        audioRef.current.pause();
      } else {
        audioRef.current.play().catch((error) => {
          console.error("Playback error:", error);
          updateState({
            error: "Failed to play audio",
          });
        });
      }
      updateState({ isPlaying: !state.isPlaying });
      lastPlayingStateRef.current = !state.isPlaying;
    }
  }, [state.isConnected, state.isPlaying]);

  const connectWebSocket = useCallback(() => {
    if (ws && ws.readyState !== WebSocket.CLOSED) {
      console.log("WebSocket already exists or is not closed");
      return;
    }

    // @ts-ignore
    const sessionId = tg?.initDataUnsafe?.auth_date;
    const device = tg?.platform || "web";
    const langParam = urlParams.get("lang") || "en";

    if (!sessionId || !telegramId) {
      updateState({
        error: "Missing required connection parameters",
        isConnected: false,
      });
      return;
    }

    try {
      const newWs = new WebSocket(
        `wss://${BASE_ORIGIN}/ws/users/${telegramId}/${sessionId}/`
      );

      newWs.onopen = () => {
        console.log("Connection established");
        updateState({
          isConnected: true,
          error: null,
          reconnectAttempts: 0,
        });

        if (lastPlayingStateRef.current && audioRef.current) {
          audioRef.current.play().catch(console.error);
          updateState({ isPlaying: true });
        }
      };

      newWs.onclose = (event) => {
        console.log("Connection closed", event.code, event.reason);
        updateState({
          isConnected: false,
          isPlaying: false,
        });

        if (state.reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
          reconnectTimeoutRef.current = setTimeout(() => {
            updateState({ reconnectAttempts: state.reconnectAttempts + 1 });
            checkNetworkStatus();
          }, RECONNECT_DELAY);
        } else {
          updateState({
            error: "Maximum number of reconnection attempts reached",
          });
        }
      };

      newWs.onerror = (error) => {
        console.error("WebSocket error:", error);
        updateState({
          error: "Connection error occurred",
          isPlaying: false,
        });
      };

      setWs(newWs);
    } catch (error) {
      console.error("Error creating WebSocket:", error);
      updateState({
        error: "Failed to create connection",
        isPlaying: false,
      });
    }
  }, [state.reconnectAttempts, telegramId]);

  useEffect(() => {
    audioRef.current = new Audio(song?.file || "");

    window.addEventListener("online", checkNetworkStatus);
    window.addEventListener("offline", checkNetworkStatus);

    connectWebSocket();

    return () => {
      if (ws) {
        ws.close();
      }
      if (reconnectTimeoutRef.current) {
        clearTimeout(reconnectTimeoutRef.current);
      }
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current = null;
      }
      window.removeEventListener("online", checkNetworkStatus);
      window.removeEventListener("offline", checkNetworkStatus);
    };
  }, []);

  useEffect(() => {
    const handleRouteChange = () => {
      if (audioRef.current) {
        lastPlayingStateRef.current = !audioRef.current.paused;
      }
    };

    return () => {
      // Cleanup for route change listener if needed
    };
  }, []);

  const checkTelegram = () => {
    if (telegramId) {
      setIsTelegramReady(true);
    }
  };

  useEffect(() => {
    checkTelegram();
  }, [telegramId]);

  useEffect(() => {
    if (onArtistOpen) {
      setValue(1);
    }
  }, [onArtistOpen]);

  const fetchUser = async () => {
    setLoading(true);
    try {
      if (telegramId) {
        await handleGetUserByTelegramId(telegramId.toString());
      }
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchUser();
  }, []);

  useEffect(() => {
    if (telegramId) {
      console.log("Telegram ID:", telegramId);
      setIsLoading(false);
    } else {
      console.log("not tgId");
    }
  }, [telegramId]);

  if (isLoading) {
    return <div>Loading...tgId</div>;
  }

  if (!telegramId) {
    return <div>Loading...</div>;
  }

  if (state.error) {
    return (
      <div className="error-container">
        <h2
          style={{ color: "#ffffff", textAlign: "center", lineHeight: "36px" }}
        >
          {state.error}
        </h2>
      </div>
    );
  }

  const handleMenuOpen = () => setOpen(true);
  const handleMenuClose = () => setOpen(false);
  const handleSetValue = (newVal: number) => setValue(newVal);
  const handleArtistOpen = () => setOnArtistOpen(!onArtistOpen);

  return (
    <I18nProvider lang={lang as "en" | "ru"}>
      <Snowfall
        snowflakeCount={350}
        color={"#4D4D4D"}
        speed={[1, 7]}
        wind={[0.5, 1]}
        style={{
          position: "fixed",
          width: "200vw",
          height: "200vh",
          zIndex: -1,
          filter: "blur(2px)",
          transform: "scale(0.5)",
          top: "auto",
          left: "auto",
        }}
      />
      {!loading && user && !user?.is_terms_accepted && (
        <Onboarding username={user?.first_name} />
      )}
      <div className={"wrapper"}>
        <Header
          onMenuOpen={handleMenuOpen}
          onArtistOpen={handleArtistOpen}
          isArtistOpen={onArtistOpen}
        />
        {open && (
          <Menu
            lang={lang as "en" | "ru"}
            onTabChange={handleSetValue}
            onClose={handleMenuClose}
          />
        )}

        <div className="pageContent">
          {!onArtistOpen ? (
            <>
              <div style={{ display: value === 0 ? "block" : "none" }}>
                <Player
                  onTabChange={handleSetValue}
                  lang={lang as "en" | "ru"}
                />
                {/* <ActiveAdds/> */}
                {/* <Onboarding username={user?.first_name} /> */}
              </div>
              {value === 1 && (
                <Stations
                  lang={lang as "en" | "ru"}
                  setStation={handleSetValue}
                />
              )}
              {value === 2 && <RefLink lang={lang as "en" | "ru"} />}
              {value === 3 && <Tasks lang={lang as "en" | "ru"} />}
              {value === 4 && <Wallet lang={lang as "en" | "ru"} />}
              {value === 5 && <Help />}
              {value === 6 && <Copyright lang={lang as "en" | "ru"} />}
              {value === 7 && <UserStatus />}
              {value === 8 && <UserAgreement button={false} />}
              {value === 9 && <GamesPage />}
              {value === 10 && (
                <LanguageSwitcher
                  onLanguageSelect={() => setIsLanguageSelected(true)}
                  first={false}
                  onClick={() => handleSetValue(0)}
                />
              )}
            </>
          ) : (
            <>
              <div style={{ display: value === 0 ? "block" : "none" }}>
                <PlayerControlsMusicians
                  onTabChange={handleSetValue}
                  lang={lang as "en" | "ru"}
                />
              </div>
              {value === 1 && (
                <Musicians
                  tab={0}
                  lang={lang as "en" | "ru"}
                />
              )}
              {value === 2 && (
                <Musicians
                  tab={1}
                  lang={lang as "en" | "ru"}
                />
              )}
              {value === 5 && <Help />}
              {value === 6 && <Copyright lang={lang as "en" | "ru"} />}
              {value === 7 && <UserStatus />}
              {value === 8 && <UserAgreement button={false} />}
              {value === 9 && <GamesPage />}
              {value === 10 && (
                <LanguageSwitcher
                  onLanguageSelect={() => setIsLanguageSelected(true)}
                  first={false}
                />
              )}
            </>
          )}
        </div>
        <div className="wrapperMenu">
          <TabNavigation
            menuOpen={open}
            onMenuOpen={handleMenuOpen}
            lang={lang as "en" | "ru"}
            value={value}
            setValue={setValue}
            isArtistOpen={onArtistOpen}
          />
        </div>
      </div>
    </I18nProvider>
  );
};

export default App;
