import { useTranslate } from "../../i18n";
import { ReactComponent as CloseIcon } from "../../assets/icons/closePopupIcon.svg";

import style from "./ModalBackground.module.css";

interface Props {
  onClose: () => void;
  handleDontShowAgain: () => void;
}

export const ModalBackground: React.FC<Props> = ({
  onClose,
  handleDontShowAgain,
}) => {
  const translate = useTranslate();
  const background = translate.help_page.background;

  return (
    <div className={style.wrapper}>
      <div className={style.textContent}>
        <CloseIcon
          onClick={onClose}
          className={style.closeIcon}
        />
        <p className={style.subtitle}>{background.title_1} </p>
        <p className={style.subtitle}>{background.title_2}</p>
        <p className={style.text}>{background.text_1} </p>
        <p className={style.text}>{background.text_2} </p>
        <p className={style.text}>{background.text_3} </p>
        <button
          className={style.claimButton}
          onClick={handleDontShowAgain}
        >
          <p>{background.dontShow}</p>
        </button>
      </div>
    </div>
  );
};
