import React, { useCallback, useEffect } from "react";
import { Slider, IconButton } from "@mui/material";
import { ReactComponent as VolumeIcon } from "../../assets/icons/player/volumtIcon.svg";
import { styled } from "@mui/system";
import debounce from "lodash/debounce";

const VolumeContainer = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  width: "100%",
  margin: "16px  2px 0  0 ",
  color: "#FFFFFF",
}));

const VolumeSlider = styled(Slider)(({ theme }) => ({
  color: "#4A78F1",
  height: 8,
  "& .MuiSlider-thumb": {
    height: 24,
    width: 24,
    background: "#9C5397",
    boxShadow:
      "0px 4px 4.900000095367432px rgba(234.55, 173.19, 255, 0.55) inset",
    transition: "all 0.2s ease",
  },
  "& .MuiSlider-track": {
    border: "none",
    background:
      "linear-gradient(90deg, rgba(213, 2, 255, 0.7) 0%, rgba(0, 179, 255, 0.7) 100%)",
    backdropFilter: "blur(20.299999237060547px)",
  },
  "& .MuiSlider-rail": {
    opacity: 0.5,
    backgroundColor: "#bfbfbf",
  },
}));

interface VolumeControlProps {
  volume: number;
  onVolumeChange: (volume: number) => void;
  onMute?: () => void;
}

const VolumeControl: React.FC<VolumeControlProps> = ({
  volume,
  onVolumeChange,
  onMute,
}) => {
  const debouncedVolumeChange = useCallback(
    debounce((newValue: number) => {
      const clampedValue = Math.max(0.1, Math.min(100, newValue));
      onVolumeChange(clampedValue);
    }, 1),
    [onVolumeChange]
  );

  useEffect(() => {
    const savedVolume = localStorage.getItem("audioVolume");
    if (savedVolume) {
      onVolumeChange(parseFloat(savedVolume));
    }
  }, []);

  const handleVolumeChange = (_event: Event, newValue: number | number[]) => {
    const value = Array.isArray(newValue) ? newValue[0] : newValue;

    localStorage.setItem("audioVolume", value.toString());

    if (value < 1) {
      onVolumeChange(0.1);
      localStorage.setItem("audioVolume", "0.1");
      return;
    }

    debouncedVolumeChange(value);
  };

  return (
    <VolumeContainer>
      <IconButton
        aria-label="volume"
        onClick={onMute}
      >
        <VolumeIcon />
      </IconButton>
      <VolumeSlider
        value={volume}
        onChange={handleVolumeChange}
        min={0}
        max={100}
        step={1}
        aria-labelledby="continuous-slider"
      />
    </VolumeContainer>
  );
};

export default VolumeControl;
