import React from "react";
import ReactDOM from "react-dom/client";
import { SDKProvider } from "@telegram-apps/sdk-react";
import "./index.css";
import App from "./App";
import { UserProvider } from "./context/UserContext";
import { FavoritesProvider } from "./context/FavoritesContext";
import { StationsProvider } from "./context/StationsContext";
import { LeaguesProvider } from "./context/LeaguesContext";
import { GamesProvider } from "./context/GameContext";
import { PlayerPrivider } from "./context/PlayerContext";
import { MusicProvider } from "./context/MusicContext";
import { TasksProvider } from "./context/TaskContext";
import { WalletProvider } from "./context/WalletContext";
import { LanguageProvider } from "./context/LanguageContext";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { BASE_URL } from "./service/axiosConfig/axiosConfig";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <TonConnectUIProvider manifestUrl="https://radiogram.website/tonconnect-manifest.json">
    <React.StrictMode>
      <SDKProvider
        acceptCustomStyles
        debug
      >
        <LanguageProvider>
          <PlayerPrivider>
            <UserProvider>
              <FavoritesProvider>
                <LeaguesProvider>
                  <GamesProvider>
                    <MusicProvider>
                      <StationsProvider>
                        <TasksProvider>
                          <WalletProvider>
                            <App />
                          </WalletProvider>
                        </TasksProvider>
                      </StationsProvider>
                    </MusicProvider>
                  </GamesProvider>
                </LeaguesProvider>
              </FavoritesProvider>
            </UserProvider>
          </PlayerPrivider>
        </LanguageProvider>
      </SDKProvider>
    </React.StrictMode>
  </TonConnectUIProvider>
);
