import { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import confetti from "canvas-confetti";
import { useTranslate } from "../../i18n";

import { ReactComponent as NoActiveDay } from "../../assets/icons/noActiveDay.svg";
import { ReactComponent as ActiveDay } from "../../assets/icons/activeDay.svg";

import style from "./PrizeForDay.module.css";

export const PrizeForDay: React.FC<{ lang: "en" | "ru" }> = ({ lang }) => {
  const { day } = useUser();

  const translate = useTranslate();
  const specialDays = [10, 20, 30];
  const [showModal, setShowModal] = useState(false);
  const trans = translate.prizeDay;

  const launchConfetti = () => {
    const duration = 5000;
    const interval = 200;
    const end = Date.now() + duration;

    const frame = () => {
      if (Date.now() >= end) return;

      confetti({
        particleCount: 50,
        spread: 70,
      });

      setTimeout(frame, interval);
    };

    frame();
  };

  useEffect(() => {
    const lastShownDate = localStorage.getItem("lastShownDate");
    const currentDate = new Date().toDateString();

    if (lastShownDate !== currentDate) {
      if (specialDays.includes(day)) {
        setShowModal(true);
        launchConfetti();
      } else {
        setShowModal(true);
        // launchConfetti();
      }

      localStorage.setItem("lastShownDate", currentDate);
    }
  }, [day, specialDays]);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  if (!showModal) {
    return null;
  }

  return (
    <div
      className={style.overlay}
      onClick={handleCloseModal}
    >
      <div className={style.wrapper}>
        <div className={style.back}></div>
        <h1 className={style.title}>{trans.day}</h1>
        <h1 className={style.titleNum}>{day}</h1>

        <p className={lang === "en" ? style.text : style.text_ru}>
          {trans.title}
        </p>
        <div className={style.days}>
          <div className={style.wrapperDay}>
            {day === 10 || day > 10 ? <ActiveDay /> : <NoActiveDay />}
            <p className={style.textDay}>{trans.day_10}</p>
          </div>
          <div className={style.wrapperDay}>
            {day === 20 || day > 20 ? <ActiveDay /> : <NoActiveDay />}
            <p className={style.textDay}>{trans.day_20}</p>
          </div>
          <div className={style.wrapperDay}>
            {day === 30 || day > 30 ? <ActiveDay /> : <NoActiveDay />}
            <p className={style.textDay}>{trans.day_30}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
