import React, { useState, useEffect } from "react";
import styles from "./FortuneWheel.module.css";
import { useUser } from "../../context/UserContext";
import { TimerGame } from "../TimerGame/TimerGame";
import { ReactComponent as CloseIcon } from "../../assets/icons/closePopupIcon.svg";
import { useGames } from "../../context/GameContext";
import confetti from "canvas-confetti";
import Bg from "../../assets/img/Group.svg";

interface Props {
  onClose: () => void;
}

const segmentNumbers = [1000, 5, 100, 10, 200, 10, 50, 200, 50, 5, 100, 500];

const FortuneWheel: React.FC<Props> = ({ onClose }) => {
  const [resultNumber, setResultNumber] = useState<number | null>(null);
  const [rotationCount, setRotationCount] = useState(0);
  const [showWheel, setShowWheel] = useState(false);
  const { user, setUser } = useUser();
  const { lastLaunchDate, fetchSpinResult } = useGames();

  useEffect(() => {
    if (resultNumber) {
      const duration = 60 * 1000;
      const animationEnd = Date.now() + duration;
      const defaults = {
        startVelocity: 20,
        spread: 360,
        ticks: 200,
        zIndex: 99999,
      };

      const interval = setInterval(() => {
        const timeLeft = animationEnd - Date.now();

        if (timeLeft <= 0) {
          clearInterval(interval);
        }

        const particleCount = 50 * (timeLeft / duration);
        confetti(
          Object.assign({}, defaults, {
            particleCount,
            origin: { x: Math.random(), y: Math.random() - 0.2 },
          })
        );
      }, 250);

      return () => clearInterval(interval);
    }
  }, [resultNumber]);

  const promiseAfterTimeout = (seconds: number): Promise<void> => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(), seconds * 1000);
    });
  };

  const initialRotationOffset = -10;

  const rotateWheel = (degr: number): Promise<void> => {
    const wheel = document.querySelector(`.${styles.weelsBg}`) as HTMLElement;
    if (wheel) {
      wheel.style.transform = `rotate(${degr + initialRotationOffset}deg)`;
    }
    return promiseAfterTimeout(3);
  };

  const findDegreesForNumber = (number: number): number => {
    const segmentIndex = segmentNumbers.findIndex((num) => num === number);
    if (segmentIndex === -1) return 0;
    const segmentDegrees = 360 / segmentNumbers.length;
    return segmentDegrees * segmentIndex;
  };

  const launchSpin = async () => {
    const result = await fetchSpinResult();

    if (result && result.points_awarded) {
      const targetRotation = findDegreesForNumber(result.points_awarded);
      const newRotation = 360 * 5 * (rotationCount + 1) - targetRotation;
      setRotationCount(rotationCount + 1);

      rotateWheel(newRotation).then(() => {
        setResultNumber(result.points_awarded);
      });
      if (user) {
        setUser((prev: any) => {
          return {
            ...prev,
            wallet: {
              ...prev?.wallet,
              balance: (
                parseInt(prev?.wallet?.balance) + result.points_awarded
              ).toString(),
            },
          };
        });
      }
    } else {
      console.error("Invalid result from API");
    }
  };

  return (
    <>
      <div className={styles.mainWrapper}>
        <CloseIcon
          onClick={onClose}
          className={styles.closeIcon}
        />
        <div className={styles.container}>
          <h1 className={styles.title}>Fortune wheel</h1>
          {resultNumber ? (
            <div className={styles.resultWrapper}>
              <div className={styles.result}>
                <p className={styles.resultText}>YOU WIN </p>
                <p className={styles.resultWinText}>{resultNumber}</p>
                <p className={styles.resultText}>IMPULSE</p>
              </div>
            </div>
          ) : (
            <div className={styles.wheelWrapper}>
              <div className={styles.shadowWrapper}>
                <div className={styles.shadow}></div>
                {/* <img src={Shadow} alt="" className={styles.shadow}/> */}
              </div>
              <div className={styles.arrow}></div>
              <div className={styles.weelsBg}>
                <img
                  src={Bg}
                  alt=""
                  className={styles.bgImg}
                />
                <div className={styles.wheel}>
                  {segmentNumbers.map((number, index) => (
                    <div
                      key={index}
                      className={styles.segment}
                      style={
                        { "--segment-index": index } as React.CSSProperties
                      }
                    >
                      <span
                        style={{ color: index % 2 === 0 ? "black" : "white" }}
                        className={styles.number}
                      >
                        {number}
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
          {resultNumber ? (
            <div className={styles.spinWrapper}>
              <TimerGame timeLeftSeconds={Number(lastLaunchDate)} />
            </div>
          ) : (
            <div className={styles.spinWrapper}>
              <button
                className={styles.spin}
                onClick={launchSpin}
              >
                <p className={styles.spinText}>SPIN</p>
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default FortuneWheel;
