import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useRef,
} from "react";

import {
  getStationById,
  getStations,
  StationInterface,
  LastStation,
  getLastStation,
  postLastStation,
} from "../service/stations/stations";
import { useUser } from "./UserContext";
interface StationsContextType {
  activeTab: number;
  station: StationInterface;
  stations: StationInterface[];
  lastStation: LastStation;
  setActiveTab: React.Dispatch<React.SetStateAction<number>>;
  handleGetStations: (lang: string) => Promise<void>;
  handleSelectStation: (station: StationInterface, lang: string) => void;
  handleGetStationById: (id: string, lang: string) => Promise<void>;
  handleGetLastStation: () => Promise<void>;
  handlePostLastStation: (
    telegramId: string,
    stationId: string
  ) => Promise<void>;
}

const StationsContext = createContext<StationsContextType | undefined>(
  undefined
);

export const StationsProvider = ({ children }: { children: ReactNode }) => {
  const [stations, setStations] = useState<any>(null);
  const [station, setStation] = useState<any>(null);

  const [activeTab, setActiveTab] = useState(0);

  const params = new URLSearchParams(window.location.search);
  const { user } = useUser();
  const [lastStation, setLastStation] = useState<LastStation>({
    user: user?.telegram_id,
    station: 0,
  });

  const handleGetStations = async (lang: string): Promise<void> => {
    try {
      const stations = await getStations(lang);

      if (stations) {
        setStations(stations);
      } else {
        console.error("Failed to fetch Stations");
      }
    } catch (error) {
      console.error("Failed to fetch Stations", error);
    }
  };

  const handleGetStationById = async (id: string, lang: string) => {
    try {
      const data = await getStationById(id, user.telegram_id.toString());
      setStation(data);
    } catch (error) {
      console.error("Failed to update stations", error);
    }
  };

  const handleSelectStation = async (
    station: StationInterface,
    lang: string
  ) => {
    setStation(station);
  };

  const hasFetchedLastStation = useRef(false);

  const handleGetLastStation = async (): Promise<void> => {
    if (hasFetchedLastStation.current) return;
    hasFetchedLastStation.current = true;

    if (user?.telegram_id) {
      try {
        const lastStation = await getLastStation(user.telegram_id.toString());
        setLastStation({
          user: user?.telegram_id,
          station: lastStation?.station,
        });
      } catch (error: any) {
        if (error.response?.status === 404) {
          console.log("No last station found for this user, setting default.");
          setLastStation({
            user: user?.telegram_id,
            station: 0,
          });
        } else {
          console.error("Failed to fetch last station", error);
        }
      }
    }
  };

  const handlePostLastStation = async (
    telegramId: string,
    stationId: string
  ): Promise<void> => {
    try {
      await postLastStation(telegramId, stationId);
      setLastStation({
        user: user.telegram_id,
        station: Number(stationId),
      })
    } catch (error) {
      console.error("Failed to post last station", error);
    }
  };
  useEffect(() => {
    if (stations && user?.telegram_id && !hasFetchedLastStation.current) {
      handleGetLastStation();
    }
  }, [stations, user]);

  useEffect(() => {
    if (lastStation && lastStation.station && stations) {
      const foundStation = stations.find(
        (station: StationInterface) => station.id === lastStation.station
      );
      if (foundStation) {
        setStation(foundStation);
      }
    }
  }, [lastStation, stations]);

  console.log(lastStation);
  return (
    <StationsContext.Provider
      value={{
        activeTab,
        station,
        stations,
        lastStation,
        setActiveTab,
        handleGetStations,
        handleSelectStation,
        handleGetStationById,
        handleGetLastStation,
        handlePostLastStation,
      }}
    >
      {children}
    </StationsContext.Provider>
  );
};

export const useStations = (): StationsContextType => {
  const context = useContext(StationsContext);
  if (context === undefined) {
    throw new Error("useStations must be used within an StationsProvider");
  }
  return context;
};
