import { FC, useEffect, useState } from "react";
import { useMusic } from "../../context/MusicContext";
import style from "./MusicianAlbums.module.css";
import { useNavigate } from "react-router-dom";
import { AlbumSongs } from "../AlbumSongs/AlbumSongs";
import { ReactComponent as Back } from "../../assets/icons/back.svg";

interface MusicianAlbumsProps {
  musicianId: number;
  onBackClick: () => void;
}

export const MusicianAlbums: FC<MusicianAlbumsProps> = ({
  musicianId,
  onBackClick,
}) => {
  const { musicians, albums, handleGetMusicianAlbums } = useMusic();
  const [selectedAlbumId, setSelectedAlbumId] = useState<number | null>(null);

  useEffect(() => {
    if (musicianId ) {
      handleGetMusicianAlbums(Number(musicianId));
    }
  }, [musicianId, albums.length, ]);

  const musician = musicians.find(
    (musician) => musician.id === Number(musicianId)
  );

  const handleClickAlbum = (albumId: number) => {
    setSelectedAlbumId(albumId);
  };

  const handleBackClick = () => {
    setSelectedAlbumId(null);
  };

  return (
    <>
      {!selectedAlbumId ? (
        <div>
          <h1 className={style.nameMusician}>{musician?.name}</h1>
          <img
            src={musician?.photo}
            alt={musician?.name}
            className={style.photoMusician}
          />

          <div className={style.wrapperAlbums}>
            {albums.map((item) => (
              <div
                key={item.id}
                className={style.wrapperItem}
                onClick={() => handleClickAlbum(item.id)}
              >
                <img
                  src={item.photo}
                  alt=""
                  className={style.albumImg}
                />
                <h3 className={style.titleAlbum}>{item.title}</h3>
              </div>
            ))}
          </div>
          <div
            className={style.fixBack}
            onClick={onBackClick}
          >
            <Back />
          </div>
        </div>
      ) : (
        <AlbumSongs
          albumId={selectedAlbumId}
          musicianId={musicianId}
          onBackClick={handleBackClick}
        />
      )}
    </>
  );
};
