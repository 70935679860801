import { axiosInstance } from "../axiosConfig/axiosConfig";
import { StationInterface } from "../stations/stations";
import { BalanceResponse } from "../wallets/wallets";

export interface UserInterface {
  id: number;
  telegram_id: number;
  first_name: string;
  last_name: string;
  username: string;
  photo_url: string;
  auth_date: Date;
  session_id: string;
  device: string;
  favorite_stations: StationInterface[];
  wallet: BalanceResponse;
  boost_multiplier: number;
  referrals: any;
  is_terms_accepted: boolean;
}

export interface StartSessionDTO {
  device: string;
  telegram_id: string;
  session_id: string;
}

export interface TypeAdds {
  id: number;
  title: string;
  text: string;
  time_views: number;
  image: string;
  link: string;
  created_at: string;
  start_at: string;
  end_at: string;
  views: number;
  clicks: number;
  price_per_click: number;
  max_clicks: number;
  weight: number;
}

export async function getUser(id: string): Promise<UserInterface> {
  const response = await axiosInstance.get(`/api/user/${id}/`);
  return response.data;
}

export async function startSession(data: StartSessionDTO) {
  const response = await axiosInstance.post(`/api/users/`, data);
  return response.data;
}

export async function updateLoginStreak(telegram_id: string) {
  const response = await axiosInstance
    .post("/api/update-login-streak/", { telegram_id })
    .catch(console.error);

  return response ? response.data : null;
}

export async function getActiveAdds() {
  const response = await axiosInstance.get("/api/a/");
  return response.data;
}

export async function postClickAdds(addId: number, telegramId: number) {
  const response = await axiosInstance.post(`/api/a/click/`, {
    ad_id: addId,
    telegram_id: telegramId,
  });
  return response.data;
}
