import React, { useEffect, useState } from "react";
import { useUser } from "../../context/UserContext";
import style from "./ActiveAdds.module.css";

const ActiveAdds = () => {
  const {
    user,
    handleActiveAdds,
    activeAdds,
    setShowAd,
    showAd,
    handleClickActiveAdds,
    setUser,
  } = useUser();

  useEffect(() => {
    const showContent = () => {
      setShowAd(false);
      handleActiveAdds();
      setTimeout(() => {
        setShowAd(true);
      }, 120000);
    };

    const interval = setInterval(
      () => {
        showContent();
      },
      activeAdds ? activeAdds?.time_views * 1000 : 1
    );

    return () => clearInterval(interval);
  }, [showAd]);

  const handleClickAdds = (addId: number) => {
    if (activeAdds) {
      handleClickActiveAdds(addId, Number(user.telegram_id));
      if (user) {
        setUser((prev: any) => {
          return {
            ...prev,
            wallet: {
              ...prev?.wallet,
              balance: (
                parseInt(prev?.wallet?.balance) + activeAdds.price_per_click
              ).toString(),
            },
          };
        });
      }
    }
  };

  return (
    <>
      {activeAdds && (
        <a
          href={activeAdds.link}
          target="_blank"
          rel="noopener noreferrer"
          onClick={() => handleClickAdds(activeAdds.id)}
        >
          {activeAdds.image ? (
            <img
              src={`https://slushairu.ru${activeAdds.image}`}
              alt=""
              className={style.img}
            />
          ) : (
            <>
              <h2 className={style.title}>{activeAdds.title}</h2>
              <p className={style.text}>{activeAdds.text}</p>
            </>
          )}
        </a>
      )}
    </>
  );
};

export default ActiveAdds;
