import React, { FC, useEffect, useRef, useState } from "react";

import { ReactComponent as CloseIcon } from "../../../assets/icons/closePopupIcon.svg";

import styles from "./TaskModal.module.css";
import { checkTask } from "../../../service/tasks/tasks";
import { useUser } from "../../../context/UserContext";
import { Fireworks } from "fireworks-js";

export type TaskType = "social" | "subscribe";
interface TaskModalProps {
  id: string;
  title: string;
  description: string;
  points: string;
  type: TaskType;
  icon: string;
  link: string;
  onClose: () => void;
}

enum TaskStatus {
  default = "default",
  check = "check",
  rejected = "rejected",
  confirmed = "confirmed",
}

const TaskModal: FC<TaskModalProps> = ({
  id,
  type,
  icon,
  link,
  points,
  description,
  title,
  onClose,
}) => {
  const { user, handleGetUserByTelegramId, setUser } = useUser();
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.default);
  const statusRef = useRef<TaskStatus>(TaskStatus.default);

  const onStart = () => {
    const isTelegramLink =
      link.includes("t.me/") || link.includes("telegram.me/");
    // @ts-ignore
    if (isTelegramLink && window.Telegram?.WebApp?.openTelegramLink) {
      // @ts-ignore
      window.Telegram.WebApp.openTelegramLink(link);
      // @ts-ignore
    } else if (window.Telegram?.WebApp?.openLink) {
      // @ts-ignore
      window.Telegram.WebApp.openLink(link);
    } else {
      window.open(link, "_blank");
    }

    setStatus(TaskStatus.check);
  };

  const onCheck = async () => {
    try {
      const response = await checkTask(user.telegram_id.toString(), id, type);

      if (response.status === 200) {
        setStatus(TaskStatus.confirmed);
        statusRef.current = TaskStatus.confirmed;
        handleGetUserByTelegramId(user.telegram_id.toString());
      } else {
        setStatus(TaskStatus.rejected);
        statusRef.current = TaskStatus.rejected;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onClaim = () => {
    setStatus(TaskStatus.confirmed);
    if (status === TaskStatus.confirmed) {
      const container = document.createElement("div");
      container.style.position = "fixed";
      container.style.top = "0";
      container.style.left = "0";
      container.style.width = "100%";
      container.style.height = "100%";
      container.style.pointerEvents = "none";
      container.style.zIndex = "999";
      document.body.appendChild(container);

      const fireworks = new Fireworks(container, {
        rocketsPoint: { min: 50, max: 50 },

        acceleration: 1.2,
        friction: 0.98,
        gravity: 1,
        particles: 100,

        brightness: { min: 50, max: 80 },
        decay: { min: 0.01, max: 0.03 },
      });

      fireworks.start();

      setTimeout(() => {
        fireworks.stop();
        document.body.removeChild(container);
      }, 5000);
    }

    onClose();

   
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const isAndroid = /Android/i.test(navigator.userAgent);

        if (isAndroid) {
          if (link.includes("?startapp")) {
            // @ts-ignore
            window.Telegram?.WebApp?.reload();
          }
        }

        setStatus((prev) => prev);
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [link]);

  return (
    <div className={styles.modalWrapper}>
      <CloseIcon
        className={styles.closeIcon}
        onClick={onClose}
      />

      <div className={styles.taskContent}>
        <img
          className={styles.img}
          src={icon}
          alt="taskIcon"
        />
        <h2 className={styles.title}>{title}</h2>
        {status === TaskStatus.default && (
          <p className={styles.description}>{description}</p>
        )}
        {status === TaskStatus.rejected && (
          <p className={styles.rejected}>You are not subscriber</p>
        )}
        {status === TaskStatus.confirmed && (
          <p className={styles.confirmed}>Confirmed</p>
        )}

        <div className={styles.rewardBlock}>
          <p className={styles.reward}>Reward</p>
          <p className={styles.rewardPoints}>{points}</p>
        </div>

        <button
          onClick={
            status === TaskStatus.default
              ? onStart
              : status === TaskStatus.check
              ? onCheck
              : status === TaskStatus.confirmed
              ? onClaim
              : onClose
          }
          className={styles.actionButton}
        >
          <p>
            {status === TaskStatus.default
              ? "Start"
              : status === TaskStatus.check
              ? "Check"
              : status === TaskStatus.rejected
              ? "Back"
              : "Claim"}
          </p>
        </button>
      </div>
    </div>
  );
};

export default TaskModal;
