import React, {
  createContext,
  useState,
  useEffect,
  useContext,
  ReactNode,
  useCallback,
} from "react";
import {
  FavoriteMusicInterface,
  getAllFavoritesByTelegramId,
  getAllFavoritesMusic,
  toggleFavorite,
  toggleMusicFavorite,
} from "../service/favorites/favorites";
import { useUser } from "./UserContext";
import { StationInterface } from "../service/stations/stations";
import { MusiciansAlbumSongsResponse } from "../service/music/music";

interface FavoritesContextType {
  favorites: StationInterface[];
  musicFavorites: FavoriteMusicInterface[];
  handleGetFavoritesByUser: (id: number) => void;
  handleGetMusicFavoritesByUser: (id: number) => Promise<void>;
  handleToggleFavorite: (
    station: StationInterface,
    telegramId: number
  ) => Promise<void>;
  handleToggleMusicFavorite: (
    song: MusiciansAlbumSongsResponse,
    telegramId: number
  ) => Promise<void>;
}

const FavoritesContext = createContext<FavoritesContextType | undefined>(
  undefined
);

export const FavoritesProvider = ({ children }: { children: ReactNode }) => {
  const { user } = useUser();

  const [favorites, setFavorites] = useState<StationInterface[]>([]);
  const [musicFavorites, setMusicFavorites] = useState<
    FavoriteMusicInterface[]
  >([]);

  const handleGetFavoritesByUser = useCallback(async (id: number) => {
    try {
      const favorites = await getAllFavoritesByTelegramId(id);
      if (favorites) {
        setFavorites(favorites);
      } else {
        console.error("Failed to fetch Favorites");
      }
    } catch (error) {
      console.error("Failed to fetch Favorites", error);
    }
  }, []);

  const handleGetMusicFavoritesByUser = async (id: number): Promise<void> => {
    try {
      const musicFavorites = await getAllFavoritesMusic(id);
      if (musicFavorites) {
        setMusicFavorites(musicFavorites);
      } else {
        console.error("Failed to fetch MusicFavorites");
      }
    } catch (error) {
      console.error("Failed to fetch MusicFavorites", error);
    }
  };

  const handleToggleFavorite = async (
    station: StationInterface,
    telegramId: number
  ) => {
    const isFavorite = favorites.some((fav) => fav.id === station.id);

    setFavorites((prevFavorites) =>
      isFavorite
        ? prevFavorites.filter((fav) => fav.id !== station.id)
        : [...prevFavorites, station]
    );

    try {
      await toggleFavorite(station.id.toString(), telegramId);

      await handleGetFavoritesByUser(telegramId);
    } catch (error) {
      console.error("Failed to update favorites", error);

      setFavorites((prevFavorites) =>
        isFavorite
          ? [...prevFavorites, station]
          : prevFavorites.filter((fav) => fav.id !== station.id)
      );
    }
  };

  const handleToggleMusicFavorite = async (
    song: MusiciansAlbumSongsResponse,
    telegramId: number
  ) => {
    const isFavorite = musicFavorites.some((fav) => fav.song.id === song.id);
    setMusicFavorites((prevFavorites) =>
      isFavorite
        ? prevFavorites.filter((fav) => fav.song.id !== song.id)
        : [
            ...prevFavorites,
            {
              id: song.id,
              user_id: telegramId,
              song: song,
            },
          ]
    );
    try {
      await toggleMusicFavorite(song.id.toString(), telegramId);
      await handleGetMusicFavoritesByUser(telegramId);
    } catch (error) {
      console.error("Failed to update favorites", error);
      setMusicFavorites((prevFavorites) =>
        isFavorite
          ? [
              ...prevFavorites,
              {
                id: song.id,
                user_id: telegramId,
                song: song,
              },
            ]
          : prevFavorites.filter((fav) => fav.song.id !== song.id)
      );
    }
  };

  // useEffect(() => {
  //   if (user) {
  //     handleGetFavoritesByUser(user.telegram_id);
  //     handleGetMusicFavoritesByUser(user.telegram_id);
  //   }
  // }, [user]);

  return (
    <FavoritesContext.Provider
      value={{
        favorites,
        musicFavorites,
        handleGetFavoritesByUser,
        handleToggleFavorite,
        handleToggleMusicFavorite,
        handleGetMusicFavoritesByUser,
      }}
    >
      {children}
    </FavoritesContext.Provider>
  );
};

export const useFavorites = (): FavoritesContextType => {
  const context = useContext(FavoritesContext);
  if (context === undefined) {
    throw new Error("useFavorites must be used within a FavoritesProvider");
  }
  return context;
};
